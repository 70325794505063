import MainComponent from "./components";

function App() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <MainComponent />
    </div>
  );
}

export default App;
