import * as React from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import {
  Box,
  Button,
  Snackbar,
  Stack,
  SvgIcon,
  Typography,
  styled,
} from "@mui/joy";
import { VariantProp } from "@mui/joy/styles";
import axios from "axios";
import { BASEURL } from "../config";
import { FormState } from "./types/data";
import logo from "../assets/logo.svg";
const style = {
  fontSize: 16,
  fontWeight: 400,
};

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
  @media (max-width: 600px) {
    clip-path: inset(100%);
    border: 1px solid black;
    width: 100%;
  }
`;

const MainComponent = () => {
  const [variant, setVariant] = React.useState<VariantProp>("solid");
  const [open, setOpen] = React.useState(false);
  const [openError, setErrorOpen] = React.useState(false);
  const [openFirst, setOpenFirst] = React.useState(false);
  const [uploadedFileNames, setUploadedFileNames] = React.useState({
    company_photo: null,
    kitchen_photo_1: null,
    kitchen_photo_2: null,
    dormitory_photo_1: null,
    dormitory_photo_2: null,
    business_registration_certificate_photo: null,
  });
  const [formData, setFormData] = React.useState<FormState>({
    business_name: "",
    industry: "",
    business_registration_certificate: "",
    address: "",
    manager: "",
    contact_person: "",
    duties: "",
    korean_language_skills: "",
    department: "",
    work_place: "",
    number_of_recruits: 0,
    visa: "",
    job_description: "",
    desired_conditions: "",
    wage_conditions: "",
    working_hours: "",
    social_insurance: "",
    retirement_benefits: "",
    dormitory: "",
    traffic: "",
    other: "",
    foreigners_working_together: "",
  });

  const [fileUploads, setFileUploads] = React.useState<
    Record<string, File | null>
  >({
    company_photo: null,
    kitchen_photo_1: null,
    kitchen_photo_2: null,
    dormitory_photo_1: null,
    dormitory_photo_2: null,
    business_registration_certificate_photo: null,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const file = e.target.files?.[0] || null;
    setFileUploads({
      ...fileUploads,
      [field]: file,
    });
    if (file) {
      setUploadedFileNames((prev) => ({
        ...prev,
        [field]: file.name,
      }));
    }
  };

  const validateForm = () => {
    if (!formData.business_name || !formData.industry) {
      setOpenFirst(true);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) return;

    const form = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      form.append(key, value);
    });
    Object.entries(fileUploads).forEach(([key, file]) => {
      if (file) {
        form.append(key, file);
      }
    });

    try {
      const response = await axios.post(`${BASEURL}/create`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        console.log("Data successfully submitted:", response.data);
        setOpen(true);
        setTimeout(() => {
          window.location.href = "https://urconnection.co.kr/";
        }, 2000);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setTimeout(() => {
        setErrorOpen(true);
      }, 1500);
    }
  };

  return (
    <Box
      sx={{
        paddingTop: 2,
        paddingBottom: 2,
        display: "flex",
        "@media (max-width: 600px)": {
          display: "grid",
          padding: 2,
          width: "100%",
        },
      }}
    >
      <FormControl>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}
        >
          <img src={logo} alt="image-icon" width={150} />
        </Box>

        <Typography sx={{ fontSize: 22, textAlign: "center" }}>
          구인신청서
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: 1.5,
            marginTop: 2,
            width: "100%",

            "@media (max-width: 600px)": {
              display: "grid",
              gridTemplateColumns: "1fr",
            },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>사업체명</FormLabel>
              <Input
                placeholder="...."
                name="business_name"
                value={formData.business_name}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>업종</FormLabel>
              <Input
                placeholder="...."
                name="industry"
                value={formData.industry}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ marginTop: 1.5 }}>
          <FormControl>
            <FormLabel sx={{ style }}>사업자등록번호</FormLabel>
            <Input
              placeholder="...."
              name="business_registration_certificate"
              value={formData.business_registration_certificate}
              onChange={handleChange}
            />
          </FormControl>
        </Box>
        <Box sx={{ marginTop: 1.5 }}>
          <FormControl>
            <FormLabel sx={{ style }}>주소</FormLabel>
            <Input
              placeholder="...."
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1.5,
            marginTop: 1.5,
            "@media (max-width: 600px)": {
              display: "grid",
              gridTemplateColumns: "1fr",
            },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>담당자</FormLabel>
              <Input
                placeholder="...."
                name="manager"
                value={formData.manager}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>담당자 연락처</FormLabel>
              <Input
                placeholder="...."
                name="contact_person"
                value={formData.contact_person}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Box>
        <Typography sx={{ fontSize: 22, textAlign: "center", marginTop: 1.5 }}>
          모집부문 및 자격요건
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1.5,
            marginTop: 1.5,
            "@media (max-width: 600px)": {
              display: "grid",
              gridTemplateColumns: "1fr",
            },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>담당업무</FormLabel>
              <Input
                placeholder="...."
                name="duties"
                value={formData.duties}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>한국어 능력</FormLabel>
              <Input
                placeholder="...."
                name="korean_language_skills"
                value={formData.korean_language_skills}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1.5,
            marginTop: 1.5,
            "@media (max-width: 600px)": {
              display: "grid",
              gridTemplateColumns: "1fr",
            },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>부서</FormLabel>
              <Input
                placeholder="...."
                name="department"
                value={formData.department}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>근무지</FormLabel>
              <Input
                placeholder="EX. 주소 동일"
                name="work_place"
                value={formData.work_place}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1.5,
            marginTop: 1.5,
            "@media (max-width: 600px)": {
              display: "grid",
              gridTemplateColumns: "1fr",
            },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>모집인원</FormLabel>
              <Input
                placeholder="...."
                name="number_of_recruits"
                value={formData.number_of_recruits}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>비자</FormLabel>
              <Input
                placeholder="...."
                name="visa"
                value={formData.visa}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ marginTop: 1.5 }}>
          <Box>
            <FormControl>
              <FormLabel sx={{ style }}>직무내용</FormLabel>
              <Input
                placeholder="...."
                name="job_description"
                value={formData.job_description}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ marginTop: 1.5 }}>
          <Box>
            <FormControl>
              <FormLabel sx={{ style }}>희망조건</FormLabel>
              <Input
                name="desired_conditions"
                placeholder="EX. 성별, 연령, 국적, 능력, 기술수준, 한국어 등"
                value={formData.desired_conditions}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Box>
        <Typography sx={{ fontSize: 22, textAlign: "center", marginTop: 1.5 }}>
          근무조건
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1.5,
            marginTop: 1.5,
            "@media (max-width: 600px)": {
              display: "grid",
              gridTemplateColumns: "1fr",
            },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>임금조건</FormLabel>
              <Input
                placeholder="...."
                name="wage_conditions"
                value={formData.wage_conditions}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>근무시간</FormLabel>
              <Input
                placeholder="...."
                name="working_hours"
                value={formData.working_hours}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1.5,
            marginTop: 1.5,
            "@media (max-width: 600px)": {
              display: "grid",
              gridTemplateColumns: "1fr",
            },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>사회보험</FormLabel>
              <Input
                placeholder="...."
                name="social_insurance"
                value={formData.social_insurance}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>퇴직급여</FormLabel>
              <Input
                placeholder="...."
                name="retirement_benefits"
                value={formData.retirement_benefits}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1.5,
            marginTop: 1.5,
            "@media (max-width: 600px)": {
              display: "grid",
              gridTemplateColumns: "1fr",
            },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>기숙사</FormLabel>
              <Input
                placeholder="...."
                name="dormitory"
                value={formData.dormitory}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
          <Box sx={{ flex: 1 }}>
            <FormControl>
              <FormLabel sx={{ style }}>교통</FormLabel>
              <Input
                placeholder="...."
                name="traffic"
                value={formData.traffic}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ marginTop: 1.5 }}>
          <Box>
            <FormControl>
              <FormLabel sx={{ style }}>기타사항</FormLabel>
              <Input
                placeholder="...."
                name="other"
                value={formData.other}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ marginTop: 1.5 }}>
          <Box>
            <FormControl>
              <FormLabel sx={{ style }}>
                함께 일하는 외국인 (국적, 비자, 성별 등)
              </FormLabel>
              <Input
                placeholder="EX.선택사항으로 외국인 모집 시 유리"
                name="foreigners_working_together"
                value={formData.foreigners_working_together}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ marginTop: 1.5 }}>
          <Box>
            <FormControl>
              <FormLabel sx={{ style }}>회사 사진:</FormLabel>
              <Button
                component="label"
                role={undefined}
                tabIndex={-1}
                variant="outlined"
                color="neutral"
                startDecorator={
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </SvgIcon>
                }
              >
                {uploadedFileNames.company_photo ? (
                  <span style={{ color: "green" }}>
                    {uploadedFileNames.company_photo}
                  </span>
                ) : (
                  "사진 업로드"
                )}
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => handleFileChange(e, "company_photo")}
                />
              </Button>
            </FormControl>
            <FormControl sx={{ marginTop: 1.5 }}>
              <FormLabel sx={{ style }}>주방 사진:</FormLabel>
              <Button
                component="label"
                role={undefined}
                tabIndex={-1}
                variant="outlined"
                color="neutral"
                startDecorator={
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </SvgIcon>
                }
              >
                {uploadedFileNames.kitchen_photo_1 ? (
                  <span style={{ color: "green" }}>
                    {uploadedFileNames.kitchen_photo_1}
                  </span>
                ) : (
                  "사진 업로드"
                )}
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => handleFileChange(e, "kitchen_photo_1")}
                />
              </Button>
              <Button
                component="label"
                role={undefined}
                tabIndex={-1}
                variant="outlined"
                color="neutral"
                sx={{
                  marginTop: 1.5,
                }}
                startDecorator={
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </SvgIcon>
                }
              >
                {uploadedFileNames.kitchen_photo_2 ? (
                  <span style={{ color: "green" }}>
                    {uploadedFileNames.kitchen_photo_2}
                  </span>
                ) : (
                  "사진 업로드"
                )}
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => handleFileChange(e, "kitchen_photo_2")}
                />
              </Button>
            </FormControl>
            <FormControl sx={{ marginTop: 1.5 }}>
              <FormLabel sx={{ style }}>기숙사 사진:</FormLabel>
              <Button
                component="label"
                role={undefined}
                tabIndex={-1}
                variant="outlined"
                color="neutral"
                startDecorator={
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </SvgIcon>
                }
              >
                {uploadedFileNames.dormitory_photo_1 ? (
                  <span style={{ color: "green" }}>
                    {uploadedFileNames.dormitory_photo_1}
                  </span>
                ) : (
                  "사진 업로드"
                )}
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => handleFileChange(e, "dormitory_photo_1")}
                />
              </Button>
              <Button
                component="label"
                role={undefined}
                tabIndex={-1}
                variant="outlined"
                color="neutral"
                sx={{
                  marginTop: 1.5,
                }}
                startDecorator={
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </SvgIcon>
                }
              >
                {uploadedFileNames.dormitory_photo_2 ? (
                  <span style={{ color: "green" }}>
                    {uploadedFileNames.dormitory_photo_2}
                  </span>
                ) : (
                  "사진 업로드"
                )}
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => handleFileChange(e, "dormitory_photo_2")}
                />
              </Button>
            </FormControl>
            <FormControl sx={{ marginTop: 1.5 }}>
              <FormLabel sx={{ style }}>사업자 등록증 사진:</FormLabel>
              <Button
                component="label"
                role={undefined}
                tabIndex={-1}
                variant="outlined"
                color="neutral"
                startDecorator={
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </SvgIcon>
                }
              >
                {uploadedFileNames.business_registration_certificate_photo ? (
                  <span style={{ color: "green" }}>
                    {uploadedFileNames.business_registration_certificate_photo}
                  </span>
                ) : (
                  "사진 업로드"
                )}
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) =>
                    handleFileChange(
                      e,
                      "business_registration_certificate_photo"
                    )
                  }
                />
              </Button>
            </FormControl>
          </Box>
        </Box>
        <Button
          size="md"
          variant={variant}
          color="primary"
          sx={{ marginTop: 1.5 }}
          onClick={handleSubmit}
        >
          완료
        </Button>
      </FormControl>
      <Snackbar
        variant="soft"
        color="success"
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        endDecorator={
          <Button
            onClick={() => setOpen(false)}
            size="sm"
            variant="soft"
            color="success"
          >
            Dismiss
          </Button>
        }
      >
        Your message was sent successfully.
      </Snackbar>
      <Snackbar
        autoHideDuration={5000}
        variant="solid"
        color="primary"
        size="lg"
        invertedColors
        open={openFirst}
        onClose={() => setOpenFirst(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={(theme) => ({
          background: `linear-gradient(45deg, ${theme.palette.primary[600]} 30%, ${theme.palette.primary[500]} 90%})`,
          maxWidth: 360,
        })}
      >
        <div>
          <Typography sx={{ mt: 1, mb: 2 }}>
            사업체명이랑 업종 입력 부탁들이겠습니다!
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              variant="solid"
              color="primary"
              sx={{
                width: 110,
              }}
              onClick={() => setOpenFirst(false)}
            >
              확인
            </Button>
          </Stack>
        </div>
      </Snackbar>
      <Snackbar
        variant="soft"
        color="danger"
        open={openError}
        onClose={() => setErrorOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        입력된 데이터를 한번도 확인 부탁들입니다!
      </Snackbar>
    </Box>
  );
};

export default MainComponent;
